import Button from '/components/Button';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import dynamic from 'next/dynamic';

const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));

export default function ModalSubscriptions({
	modalSubscriptionOpen,
	product,
	setModalSubscriptionOpen,
}) {
	if (product.productType == 'Laundry Detergent Sheets') {
		return (
			<Modal
				id="how-subscriptions-work"
				isOpen={modalSubscriptionOpen}
				toggle={(val) => setModalSubscriptionOpen(val)}>
				<ModalBody>
					<button
						aria-label="Close"
						className="absolute top-0 right-0 p-2"
						data-dismiss="modal"
						onClick={() => setModalSubscriptionOpen(!modalSubscriptionOpen)}
						type="button">
						<Icon height="18px" name="close" width="18px" />
					</button>

					<div
						className="flex flex-col text-center bg-contain bg-bottom bg-no-repeat bg-[#ffe814]"
						style={{
							backgroundImage:
								"url('https://cdn.shinesty.com/2023-09-12/how-subs-works-fos-bg.jpg')",
						}}>
						<div>
							<div className="p-6 pb-14 md:pb-0 max-w-xl m-auto">
								<Typography component="h1" variant="heading-md">
									Put Your Laundry Detergent On Auto Pilot
								</Typography>
								<Typography component="p" variant="body">
									A Full of Sheet plan is the most convenient and cost effective way to get our best
									selling laundry detergent sheets.
									<ul className="mt-4">
										<li>Save a Metric Ton</li>
										<li>Get Free Shipping Every Time</li>
										<li>Modify Your Frequency or Shipments Anytime</li>
										<li>No Commitments, Ever</li>
									</ul>
								</Typography>
							</div>
						</div>
						<div className="relative">
							<div className="px-6 md:pt-12 pb-0 max-w-xl m-auto">
								<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
									<div>
										<Image
											alt="Free Shipping"
											className="w-full m-auto invert"
											height={50}
											src="https://cdn.shopify.com/s/files/1/0234/5963/files/Boxer-sub-shipping.png?848542772783358130"
											width={80}
										/>
										<Typography component="p" variant="body">
											Free Shipping
										</Typography>
									</div>
									<div>
										<Image
											alt="Cancel Anytime"
											className="w-full m-auto"
											height={50}
											objectFit="contain"
											src="https://cdn.shinesty.com/2023-09-12/heart-offset-gray.png"
											width={99}
										/>
										<Typography component="p" variant="body">
											No Commitments. Cancel Anytime.
										</Typography>
									</div>
									<div>
										<Image
											alt="pair for free"
											className="w-full m-auto invert"
											height={50}
											src="https://cdn.shopify.com/s/files/1/0234/5963/files/Boxer-sub-cancel.png?848542772783358130"
											width={99}
										/>
										<Typography component="p" variant="body">
											Cancel or pause anytime
										</Typography>
									</div>
								</div>
							</div>
						</div>
						<div className="-mt-12 md:mt-0">
							<div className="p-6 pt-52 max-w-xl m-auto">
								<Button
									className="my-4 w-full"
									onClick={() => setModalSubscriptionOpen(false)}
									type="button"
									variant="filled">
									Got it
								</Button>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		);
	}
	if (product?.productType?.includes('Socks')) {
		return (
			<Modal
				id="how-subscriptions-work"
				isOpen={modalSubscriptionOpen}
				toggle={(val) => setModalSubscriptionOpen(val)}>
				<ModalBody>
					<button
						aria-label="Close"
						className="absolute top-2 right-2 p-2 z-10 text-white hover:bg-black/10 rounded-full transition-colors"
						data-dismiss="modal"
						onClick={() => setModalSubscriptionOpen(!modalSubscriptionOpen)}
						type="button">
						<Icon height="24px" name="close" width="24px" />
					</button>

					<div
						className="flex flex-col bg-cover bg-top bg-no-repeat bg-[#ffe814] text-white w-full h-screen md:w-[576px] md:h-[680px]"
						style={{
							backgroundImage: "url('https://cdn.shinesty.com/2023-12-11/sock-subs-modal.jpg')",
						}}>
						<div className="flex-grow p-6 md:p-8">
							<Typography
								component="h1"
								variant="heading-lg"
								className="text-4xl md:text-5xl font-bold leading-tight mb-4">
								HOW
								<br />
								MEMBERSHIP
								<br />
								WORKS
							</Typography>
							<Typography
								className="font-semibold mb-6 md:absolute md:top-8 md:right-8 md:w-[45%]"
								component="p"
								variant="small">
								Get a fresh pair of socks delivered monthly. You&apos;ll never get the same pair
								twice.
							</Typography>

							<div className="space-y-4 mb-6">
								{[
									'Save 29% Per Pair + Free Shipping.',
									'No Commitments. Cancel Anytime.',
									"Love your first pair or they're free",
								].map((text, index) => (
									<div key={index} className="flex items-center gap-3">
										<div className="rounded-full p-1 flex-shrink-0">
											<Image
												alt="Checkmark"
												className="w-5 h-5"
												src="https://cdn.shinesty.com/2023-12-11/white-circle-checkmark.svg"
												width={20}
												height={20}
											/>
										</div>
										<Typography component="p" variant="body" className="text-sm md:text-base">
											{text}
										</Typography>
									</div>
								))}
							</div>
						</div>

						<div className="mt-auto p-6">
							<Typography className="text-xs md:text-sm mb-6" component="p" variant="small">
								We will automatically configure your subscription to deliver similar style as the
								first pair you select now. After your first pair arrives you can manage the types of
								socks you get and how often you get them from your account.
							</Typography>
							<Button
								className="w-full bg-gray-600 hover:bg-gray-700 transition-colors py-3 text-base md:text-lg font-semibold"
								onClick={() => setModalSubscriptionOpen(false)}
								type="button"
								variant="filled">
								Got it
							</Button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		);
	}
	return (
		<Modal
			id="how-subscriptions-work"
			isOpen={modalSubscriptionOpen}
			toggle={(val) => setModalSubscriptionOpen(val)}>
			<ModalBody className="p-0">
				<button
					aria-label="Close"
					className="absolute top-2 right-2 p-2 z-10 text-white hover:bg-black/10 rounded-full transition-colors"
					data-dismiss="modal"
					onClick={() => setModalSubscriptionOpen(!modalSubscriptionOpen)}
					type="button">
					<Icon height="24px" name="close" width="24px" />
				</button>
				<div
					className="flex flex-col bg-cover bg-top bg-no-repeat bg-[#00A9D8] text-white w-full h-screen md:w-[576px] md:h-[680px]"
					style={{
						backgroundImage: "url('https://cdn.shinesty.com/2024-01-26/underwear-modal-bg.jpg')",
					}}>
					<div className="flex-grow p-6 md:p-8">
						<Typography
							component="h1"
							variant="heading-lg"
							className="text-4xl md:text-5xl font-bold leading-tight mb-4">
							HOW
							<br />
							MEMBERSHIP
							<br />
							WORKS
						</Typography>
						<Typography
							className="font-semibold mb-6 md:absolute md:top-8 md:right-8 md:w-[45%]"
							component="p"
							variant="small">
							Get a fresh pair of underwear delivered monthly. You&apos;ll never get the same pair
							twice.
						</Typography>

						<div className="space-y-4 mb-6">
							{[
								'Save 35% Per Pair + Free Shipping.',
								'No Commitments. Cancel Anytime.',
								"Love your first pair or they're free.",
							].map((text, index) => (
								<div key={index} className="flex items-center gap-3">
									<div className="rounded-full p-1 flex-shrink-0">
										<Image
											alt="Checkmark"
											className="w-5 h-5"
											src="https://cdn.shinesty.com/2023-12-11/white-circle-checkmark.svg"
											width={20}
											height={20}
										/>
									</div>
									<Typography component="p" variant="body" className="text-sm md:text-base">
										{text}
									</Typography>
								</div>
							))}
						</div>
					</div>

					<div className="mt-auto p-6">
						<Typography className="text-xs md:text-sm mb-6" component="p" variant="small">
							We will automatically configure your subscription to deliver similar style as the
							first pair you select now. After your first pair arrives you can manage the types of
							products you get and how often you get them from your account.
						</Typography>
						<Button
							className="w-full bg-v2-off-white text-[#00A9D8] hover:bg-sky-100 transition-colors py-3 text-base md:text-lg font-semibold"
							onClick={() => setModalSubscriptionOpen(false)}
							type="button"
							variant="filled">
							Got it
						</Button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}

ModalSubscriptions.propTypes = {
	modalSubscriptionOpen: PropTypes.bool.isRequired,
	product: PropTypes.object,
	setModalSubscriptionOpen: PropTypes.func.isRequired,
};
