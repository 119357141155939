import PropTypes from 'prop-types';
import Script from 'next/script';
import Typography from '/components/Typography';

const Klaviyo = ({ config }) => {
	return (
		<>
			<Script
				src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=gsMr66"
				type="text/javascript"
				strategy="afterInteractive"
			/>
			<div className="container max-w-md mb-3 md:mb-4">
				{config.heading && (
					<Typography className="mb-2 text-center md:mb-4" component="h2" variant="subhead-lg">
						{config.heading}
					</Typography>
				)}
				<div dangerouslySetInnerHTML={{ __html: config.formElement }}></div>
			</div>
		</>
	);
};

Klaviyo.propTypes = {
	config: PropTypes.object,
};

export default Klaviyo;
